<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/orders">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-3"
                label="Customer *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabelForCustomer"
                  label="username"
                  v-model="customerId"
                  :options="customers"
                  placeholder="Select Customer"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.username }} - ({{ option.email }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Restaurant *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="selected"
                  :options="users"
                  placeholder="Select Restaurant"
                  @select="onSelect"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.name }} - ({{
                        option.current_address
                      }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <br /><br />
            </div>
          </div>
        </div>
        <br />

        <div class="card">
          <template>
            <MenuItems
              :meunItems="meunItems"
              @totalPriceIncrement="totalPriceIncrement"
              @deleteData="deleteData"
              :meunItemsTempArray="meunItemsTempArray"
              @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
            />
          </template>

          <br />
        </div>

        <br />

        <div class="card">
          <template>
            <DiscountList
              :meunItems="meunItems"
              @totalPriceIncrement="totalPriceIncrement"
              @deleteData="deleteData"
              :meunItemsTempArray="meunItemsTempArray"
              @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
            />
          </template>

          <br />
        </div>

        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div></div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group label="Type:">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="type"
                  @change="onChangeType()"
                >
                  <b-form-radio value="1">Delivery</b-form-radio>
                  <b-form-radio value="0">Pickup</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <div v-if="showInputForDelivery">
                <b-form-group
                  id="input-group-3"
                  label="Delivery Address *"
                  label-for="input-3"
                >
                  <multiselect
                    ref="meunItems"
                    track-by="id"
                    :custom-label="customLabelDeliveryAddress"
                    label="username"
                    v-model="deliveryAddress"
                    :options="deliveryAddresses"
                    placeholder="Select Delivery Address"
                    @remove="onDeliveryAddressRemove"
                    @select="onSelectDeliveryAddress"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><strong
                        >({{ option.street }} {{ option.city }}
                        {{ option.state }})</strong
                      ></template
                    >
                  </multiselect>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Street Address:"
                  label-for="input-2"
                >
                  <b-form-input
                    v-model="street_address"
                    type="text"
                    placeholder="Street Address"
                  ></b-form-input>
                </b-form-group>
              </div>

              <b-form-group
                id="input-group-2"
                label="Total Price:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="totalPrice"
                  type="number"
                  required
                  placeholder="Total Price"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Order Status *"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="orderStatus"
                  @change="onChangeOrderStatus"
                  :options="orderStatuses"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                v-if="showDeliveryTimeInput"
                id="input-group-3"
                label="Delivery Time:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="deliveryTime"
                  :options="deliveryTimes"
                  required
                ></b-form-select>
              </b-form-group>

              <div class="col-sm-2 col-md-2">
                <b-button class="btn-info" ref="save" type="submit"
                  >Save</b-button
                >
              </div>

              <br /><br />
            </div>
          </div>
        </div>
        <br /><br />
        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteMeunItem="deleteMeunItem"
            @closeModal="closeModal"
          />
        </template>

        <br /><br />
        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";

import VerficationModal from "./VerficationModal";
import MenuItems from "@/view/pages/Orders/MenuItems";
import DiscountList from "@/view/pages/Orders/DiscountList";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  components: {
    Multiselect,
    VerficationModal,
    DiscountList,
    MenuItems,
  },
  data() {
    return {
      deliveryAddresses: [],
      meunItemsTempArray: [],
      deliveryAddress: null,
      minimunOrderAmout: null,
      deliveryCost: null,
      customers: [],
      delivery_time: "",
      street_address: "",
      type: "1",
      customerId: null,
      headerTitle: "Add Order",
      meunItems: [],
      totalMenuItemsPrice: 0.0,
      meunItemsIds: [],
      totalPrice: 0.0,
      changeClasss: false,
      showDeliveryTimeInput: false,
      showInputForDelivery: true,
      currentAddressname: "",
      email: "",
      name: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      imagesShow: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      price: "",
      show: true,
      apiUrl: "/user/orderPlace",
      orderStatus: null,
      orderStatuses: [
        { text: "Select Order Status", value: null },
        "pending",
        "accepted",
        "rejected",
        "cooking",
        "readytopickup",
        "dispatched",
        "completed",
      ],

      deliveryTime: null,
      deliveryTimes: [
        { text: "Select Delivery Time", value: null },
        "30 Min",
        "60 Min",
        "90 Min",
        "120 Min",
      ],
    };
  },

  mounted() {
    document.title = "Add Order";
    this.getAllRestaurant();
    this.getAllCustomers();
    if (this.$router.history.current.path == "/editorder") {
      this.headerTitle = "Edit Order";
      document.title = "Edit Order";

      this.editDataApppend();
    }
  },
  methods: {
    onChangeType() {
      if (this.type == "0") {
        this.showInputForDelivery = false;
      } else {
        this.showInputForDelivery = true;
      }
    },
    onChangeOrderStatus() {
      if (this.orderStatus == "accepted") {
        this.showDeliveryTimeInput = true;
      } else {
        this.showDeliveryTimeInput = false;
      }
    },
    onSelect(option) {
      this.totalPrice = 0.0;
      this.meunItemsIds = [];
      this.meunItemsTempArray = [];
      this.deliveryAddress = null;
      this.meunItems = option.meunItems;
      this.deliveryAddresses = option.addresses;
      this.delivery_time = option.average_delivery_time;
    },

    onSelectDeliveryAddress(option) {
      this.minimunOrderAmout = option.minimum_order_amount;
      this.deliveryCost = option.delivery_cost;
      const totalMenuItemsPrice = this.meunItemsTempArray.reduce(
        (sum, equity) => {
          return sum + equity.total_price;
        },
        0
      );
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(totalMenuItemsPrice) + parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },
    onDeliveryAddressRemove(option) {
      this.minimunOrderAmout = option.minimum_order_amount;
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(this.totalPrice) - parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },

    editDataApppend() {
      this.editorder = JSON.parse(localStorage.getItem("editorder"));
      this.id = this.editorder.id;
      this.meunItemsTempArray = this.editorder.customerOrdersDetail;
      this.selected = this.editorder.restaurant;
      this.meunItems = this.editorder.meunItem;
      this.customerId = this.editorder.customerDetail;
      this.totalPrice = this.editorder.totalprice;
      this.orderStatus = this.editorder.order_status;
      if (this.orderStatus == "accepted") {
        this.showDeliveryTimeInput = true;
      } else {
        this.showDeliveryTimeInput = false;
      }
      this.street_address = this.editorder.street_address;
      this.deliveryAddresses = this.editorder.restaurantAddress;
      this.deliveryAddress = this.editorder.orderDeliveryAddress;

      this.deliveryTime = this.editorder.delivery_time;
      if (this.editorder.ordertype == "Delivery") {
        this.type = "1";
        this.showInputForDelivery = true;
      } else {
        this.type = "0";
        this.showInputForDelivery = false;
      }

      this.id = this.editorder.id;
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    appendRow() {
      this.deliveryAddressArray.push(this.deliveryAddresstempArray[0]);
      this.$refs.autocomplete.clear();
      this.toasterMessage();
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteMeunItem(object) {
      let index = this.meunItemsTempArray.findIndex(
        (x) => x.menu_id === object.item.menu_id
      );
      this.meunItemsTempArray.splice(index, 1);
      let grandTotal =
        parseFloat(this.totalPrice) - parseFloat(object.item.total_price);
      this.totalPrice = parseFloat(grandTotal).toFixed(2);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },
    CurrentAddress: function(addressData) {
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.current_address = data;
    },
    customLabel({ name, current_address }) {
      return `${name}—(${current_address})`;
    },

    totalPriceIncrement(price) {
      let grandTotal = parseFloat(this.totalPrice) + parseFloat(price);
      this.totalPrice = parseFloat(grandTotal).toFixed(2);
    },

    customLabelForCustomer({ username, email }) {
      return `${username}—(${email})`;
    },
    customLabelDeliveryAddress({ street, city, state }) {
      return `(${street} ${city} ${state})`;
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        // if (this.minimunOrderAmout > this.totalPrice) {
        //   evt.preventDefault();
        //   this.validationMessage("Minimum Order Amount will be greater then ");
        //   return false;
        // }

        if (this.customerId == null) {
          this.validationMessage("Please select customer");
          evt.preventDefault();
          return false;
        }

        if (this.selected == null) {
          this.validationMessage("Please select restaurant");
          evt.preventDefault();
          return false;
        }

        if (this.meunItemsTempArray.length == 0) {
          this.validationMessage("Please select item");
          evt.preventDefault();
          return false;
        }
        if (this.type == "1") {
          if (this.deliveryAddress == null) {
            this.validationMessage("Delivery Address Is Required");
            evt.preventDefault();
            return false;
          }
        }
        const formData = new FormData();
        evt.preventDefault();
        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("customer_id", this.customerId.id);
        formData.append("restaurant_id", this.selected.id);
        formData.append("totalprice", this.totalPrice);
        formData.append("order_type", this.type);
        formData.append("order_status", this.orderStatus);
        formData.append("street_address", this.street_address);
        formData.append("delivery_time", this.deliveryTime);
        formData.append("payment_status", 0);
        formData.append("menuitems", JSON.stringify(this.meunItemsTempArray));
        formData.append(
          "deliveryAddress",
          JSON.stringify(this.deliveryAddress)
        );

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "orders" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getAllRestaurant() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/restaurant/list", "", {
            headers,
          })
          .then((response) => {
            this.users = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomers() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/user-dropdown", "", {
            headers,
          })
          .then((response) => {
            this.customers = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
