<template>
  <div class="card-body">
    <h4 class="card-title mb-5">Discounted List</h4>
    <!-- <div style="display:flex;justify-content:space-between;align-items:center;">
      <div style="width:62%">
        <b-form-group
          id="input-group-3"
          label="Offer Name * "
          label-for="input-3"
        >
          <multiselect
            ref="meunItems"
            track-by="id"
            :custom-label="customLabel"
            label="username"
            v-model="meunItemsIds"
            :options="meunItems"
            @remove="onMenuItemRemove"
            placeholder="Select Menu Items"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong
                >{{ option.name }} - ({{ option.category.name }})</strong
              ></template
            >
          </multiselect>
        </b-form-group>
      </div>
      <div style="width:30%;margin-left:10px">
        <b-form-group label="Quantity *">
          <b-form-input
            id="input-2"
            :formatter="lengthValidation"
            @keypress="IsNumber($event)"
            v-model="quantity"
            placeholder="Enter Quantity"
          ></b-form-input>
        </b-form-group>
      </div>
      <div style="width:10%;padding-left:10px">
        <a class="btn btn-info" @click="appendRow">Add</a>
      </div>
    </div> -->
    <hr />
    <div class="row mb-md-2">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
    </div>

    <div class="table-responsive mb-0">
      <b-table
        :items="discountList"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        ref="selectableTable"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
        empty-text="No record found"
        empty-filtered-text="No search result found"
      >
        <template v-slot:cell(offername)="discountList">
          <span
            @click="discountedMenuDetail(discountList.item.discountItemsList)"
            style="cursor: pointer;"
            title="Click Here For Show Request List"
            >{{ discountList.item.offername }}</span
          >
        </template>
        <!-- <template v-slot:cell(id)="discountList">
          <input
            type="checkbox"
            :value="discountList.item.id"
            v-model="checkedNames"
          />
        </template>

        <template v-slot:cell(actions)="discountList">
          <a
            v-bind:class="'btn btn-danger btn-sm'"
            @click="deleteData(discountList)"
          >
            Delete
          </a>
        </template> -->
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>;

<script>
// import Multiselect from "vue-multiselect";

import Vue from "vue";
export default {
  components: {
    // Multiselect,
  },
  props: {
    discountList: {
      type: Array,
    },
    meunItemsTempArray: {
      type: Array,
    },
  },
  data() {
    return {
      checkedNames: [],
      quantity: "",
      meunItemsIds: [],
      deliveryAddresstempArray: [],
      deliveryAddressArray: [],
      deliveryCost: "",
      minimumOrderAmount: "",
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "offername", label: "Offer Name", sortable: true },
        { key: "quantity", label: "Quantity", sortable: true },
        { key: "offerType", label: "offer Type", sortable: true },
        { key: "actual_price", label: "Actual Price", sortable: true },
        { key: "discountedPrice", label: "Discounted price", sortable: true },
        // { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {},

  computed: {
    rows() {
      return this.discountList.length;
    },
  },

  methods: {
    IsNumber(event) {
      if (/[^0-9]/.test(event.key)) return event.preventDefault();
    },

    lengthValidation(e) {
      return String(e).substring(0, 4);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    discountedMenuDetail(discountMenuItemsList) {
      this.$emit("showDiscountedMenuItemModel", discountMenuItemsList);
    },

    customLabel({ name, category }) {
      return `${name}—(${category.name})`;
    },

    onMenuItemRemove({ price }) {
      this.totalPrice = this.totalPrice - price;
    },

    onlyForCurrency($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.deliveryCost.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.deliveryCost != null &&
        this.deliveryCost.indexOf(".") > -1 &&
        this.deliveryCost.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    appendRow() {
      if (this.meunItemsIds.length == 0) {
        this.toasterMessageFalse("Please select item");
        return false;
      }

      if (this.quantity == "") {
        this.toasterMessageFalse("Please enter quantity");
        return false;
      }
      if (this.quantity <= 0) {
        this.toasterMessageFalse("Please enter quantity greater than zero");
        return false;
      }

      let data = {
        name: this.meunItemsIds.name,
        menu_id: this.meunItemsIds.id,
        price: this.meunItemsIds.price,
        quantity: this.quantity,
        total_price: this.meunItemsIds.price * this.quantity,
      };
      this.meunItemsTempArray.push(data);
      this.$emit(
        "totalPriceIncrement",
        this.meunItemsIds.price * this.quantity
      );
      this.meunItemsIds = [];
      this.quantity = "";
    },

    toasterMessageSuccess(message) {
      Vue.$toast.success(message, {
        position: "top-right",
        duration: 5000,
      });
    },
    toasterMessageFalse(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object) {
      this.$emit("deleteData", object, "delete-menu-item");
    },
  },
};
</script>
